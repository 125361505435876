import React                  from 'react'
import Img                    from "gatsby-image"
// import cx                     from 'utils/classnames'
// import {
//   Col,
// }                             from 'react-bootstrap'
import styles                 from './styles.module.scss'


const SquareGrid = ({ children }) => (
  <div className={styles.root}>
    {children}
  </div>
)


SquareGrid.Item = ({ image, bgImage, description, children }) => (
  <div className={styles.item}>
    <div className={styles.itemContent}>
      <div className={styles.background}>
        <Img className={styles.itemBgImg} fluid={{ ...bgImage, aspectRatio: 1 }} />
        <div className={styles.backgroundBody}>
          {description}
        </div>
      </div>
      <div className={styles.foreground}>
        <Img className={styles.itemImg} fluid={{ ...image, aspectRatio: 1 }} />
        <div className={styles.itemBody}>
          {children}
        </div>
      </div>
    </div>
  </div>
)

export default SquareGrid
