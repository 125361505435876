import React, { useState }  from 'react'
import Img                  from "gatsby-image"
// import Img                  from "gatsby-image/withIEPolyfill"
import { map }              from 'lodash-es'
import RawHtml              from 'entrada-ui/RawHtml'
import cx                   from 'utils/classnames'
import {
    FaMapMarkerAlt,
    FaClock,
    FaTag,
    FaThumbsUp,
}                           from 'react-icons/fa'
import {
    Container,
    Row,
    Col
}                           from 'react-bootstrap'
import {
    useWindowSize,
}                           from 'utils'
import Icon                 from 'entrada-ui/Icon'
import Typography           from 'entrada-ui/Typography'
import Banner               from 'components/Banner'
import Link                 from 'components/Link'
import Seo                  from 'components/Seo'
import Slideshow            from 'components/Slideshow'
import SquareGrid           from 'components/SquareGrid'
import TourCards            from 'components/TourCards'
import TourSearch           from 'components/TourSearch'
import styles               from './styles.module.scss'

const TourCard = TourCards.CardWp


export default (props) => {
    const {
        pageContext: {
            data: {
                siteMetadata,
                title,
                content,
                acf: {
                    featured_image,
                    features,
                    subtitle,
                    related_contents
                },
                yoast_meta
            }
        }
    } = props

    const wSize = useWindowSize()


    return (
        <>
            <Seo meta={yoast_meta} siteMetadata={siteMetadata} />
            {featured_image &&
            <div className={styles.hero}>
                <Img
                    fluid={{
                        ...featured_image.localFile.childImageSharp.fluid,
                        aspectRatio: 16/9,
                    }}
                    className={styles.heroImg}
                    // objectFit='cover'
                    // objectPosition='50% 50%'
                />
                <div className={styles.heroInfo}>
                    <h3 className={styles.heroSubtitle}>{subtitle}</h3>
                    <h1 className={styles.heroTitle}>{title}</h1>
                    <Link className={cx('btn btn-primary', styles.heroBtn)} to="/tour-details/">
                        Tour Overview
                    </Link>
                </div>
            </div>
            }

            <Container className={styles.homeContent}>
                <RawHtml html={content} />
            </Container>

            {/* TODO: create a 'TheFeatures' component for this below */}
            <Container className={styles.theFeatures}>
                <Row>
                    {map(features, (item, idx) => (
                        <Col xs={6} md={3} key={idx} className={styles.theFeature}>
                        {/* <div className="the-feature" key={idx}> */}
                            {item.image
                                ? <Img fluid={item.image.localFile.childImageSharp.fluid} className={styles.theFeatureImg} />
                                : <div className={styles.theFeatureEmptyImg}></div>
                            }
                            
                            {item.description &&
                            <div className={styles.theFeatureTitle}>{item.title}</div>
                            }
                            
                            {item.description &&
                            <p className={styles.theFeatureDescription}>{item.description}</p>
                            }
                        {/* </div> */}
                        </Col>
                    ))}
                </Row>
                <Link className={cx('btn btn-secondary', styles.theFeaturesBtn)} to="/tour-details/">
                    Tour Overview
                </Link>
            </Container>

            {/* TODO: create a 'PageCard' component for this below */}
            <Container className="related-pages-container">
                <Row className="related-pages">

                    {map(related_contents, (item, idx) => (
                    // TODO: add variations if less than 4 items
                    <Col xs={12} md={6} xl={3} key={idx}>
                        <div className="related-page-card">
                            {item.acf.featured_image
                                ? <Link to={item.path}><Img fluid={item.acf.featured_image.localFile.childImageSharp.fluid} className="related-page-img" /></Link>
                                : <Link to={item.path}><div className="related-page-empty-img"></div></Link>
                            }
                            <div className="related-page-info">
                                <Link className="related-page-link" to={item.path}>
                                    {/* TODO: fix these 'replace' below */}
                                    {item.title.replace('&#038;', '&').replace('&#39', "'").replace('&#8216;', "'").replace('&#8216;', "'").replace('&#8211;', '-').replace('&#8211;', '-').replace('&#8217;', "'").replace('&#8217;', "'")}
                                </Link>
                                {item.excerpt &&
                                <RawHtml className="related-page-excerpt" html={item.excerpt} />
                                }
                            </div>
                        </div>
                    </Col>
                    ))}

                </Row>
            </Container>
        </>
    )
}
